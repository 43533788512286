<template>
    <div class="details-wrapper">
        <div class="filter-wrapper">
            <div>
                <el-input
                    class="formItem"
                    v-model="findObj.filledName"
                    placeholder="请输入姓名"
                    clearable
                    v-if="isShowFilter"
                ></el-input>
                <el-cascader
                    v-model="findObj.organIdList"
                    v-if="isShowFilter"
                    class="formItem"
                    :options="zzOption"
                    placeholder="请选择组织架构"
                    :props="props"
                    collapse-tags
                    clearable
                ></el-cascader>
                <el-date-picker
                    class="formItem2"
                    v-model="findObj.timeArr"
                    type="datetimerange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd HH:mm:ss"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
                <el-select
                    v-model="findObj.fillStatus"
                    class="formItem"
                    placeholder="请选择提交状态"
                    clearable
                >
                    <el-option
                        label="未提交"
                        value="1"
                    ></el-option>
                    <el-option
                        label="已提交"
                        value="2"
                    ></el-option>
                    <el-option
                        label="无效答卷"
                        value="3"
                    ></el-option>
                </el-select>
            </div>
            <div class="fiBtn">
                <el-button @click="resetBtn">重置</el-button>
            </div>
            <div class="fiBtn">
                <el-button
                    type="primary"
                    @click="searchBtn"
                    >查询</el-button
                >
            </div>
            <div class="btnLine"></div>
            <div class="fiBtn">
                <el-button
                    :loading="exportLoading"
                    type="primary"
                    @click="exportList"
                    >导出全部数据</el-button
                >
            </div>
        </div>
        <div class="details-content">
            <table-data
                v-loading="loadingTable"
                :to-top="false"
                id="table"
                ref="table"
                :config="tableConfig"
                :tableData="tableData"
            >
                <template v-slot:formTitle="slotData">
                    <div
                        style="color: #3c7fff; cursor: pointer"
                        @click="openDetails(slotData.data)"
                    >
                        {{ slotData.data.name }}
                    </div>
                </template>
                <template v-slot:fillStatus="slotData">
                    <StatusCard :status="slotData.data.fillStatus"></StatusCard>
                </template>
                <template v-slot:operation="slotData">
                    <div v-if="slotData.data.fillStatus == 1">-</div>
                    <div v-else>
                        <el-button
                            type="text"
                            @click="showAnswer(slotData.data)"
                            >查看答卷</el-button
                        >
                        <el-button
                            v-if="slotData.data.fillStatus == 3"
                            class="invalid-btn"
                            type="text"
                            @click="markPaper(slotData.data, '有效')"
                            >标记为有效答卷</el-button
                        >
                        <div
                            v-if="slotData.data.fillStatus == 2"
                            @click="markPaper(slotData.data, '无效')"
                            class="wuxiao"
                        >
                            标记为无效答卷
                        </div>
                    </div>
                </template>
            </table-data>
            <dialog-wrapper
                class="details-dia"
                :dialog-obj="detailsDialog"
                @handleClose="handleDetailsClose"
            >
                <div class="import-wrap">
                    <div style="text-align: center">
                        <phone-details
                            :detailsObj="detailsObj"
                            :formTitle="formTitle"
                        ></phone-details>
                    </div>
                    <div class="close-box">
                        <i
                            class="el-icon-circle-close icon-color"
                            @click="handleDetailsClose"
                        ></i>
                    </div>
                </div>
            </dialog-wrapper>
        </div>
        <div style="flex-shrink: 0; padding-bottom: 10px">
            <Pagination
                :page.sync="findObj.pageNum"
                :limit.sync="findObj.pageRow"
                :total="findObj.total"
                @pagination="changeTableDataGet"
            />
        </div>
    </div>
</template>

  <script>
import {
    Loading,
    Error,
    Pagination,
    DialogWrapper,
    debounce,
} from "common-local";
import GlobalPageBack from "@/components/scrollWrapper/Sub/GlobalPageBack/index.vue";
import TableData from "@/components/scrollWrapper/Sub/TableData";
import PhoneDetails from "@/components/scrollWrapper/InterconnectionInfoCollection/phoneDetailsResults.vue";
import {InterconnectionInfoCollectionStatostics} from "@/models/InterconnectionInfoCollectionStatostics.js";
import StatusCard from "../InterconnectionInfoCollection/StatusCardUser.vue";
import {mapState} from "vuex";
import {listToTree, downloadFile} from "@/libs/utils.js";
export default {
    name: "UserFillingStatus",
    props: {
        id: {
            default: () => {
                return "";
            },
        },
        userPopulation: {
            default: () => {
                return "";
            },
        },
    },
    components: {
        GlobalPageBack,
        TableData,
        PhoneDetails,
        Loading,
        Error,
        Pagination,
        DialogWrapper,
        StatusCard,
    },
    computed: {
        ...mapState({
            schoolId: (state) => state.schoolId,
        }),
    },
    data() {
        return {
            exportLoading: false,
            itemType: "其他",
            isShowFilter: true,
            isImage: false,
            props: {
                multiple: true,
                emitPath: false,
                checkStrictly: true,
                value: "id",
                label: "name",
            },
            zzOption: [], // 组织架构
            xiaOption: [], // 下拉内容
            findObj: {
                schoolId: "",
                formId: "",
                pageNum: 1,
                pageRow: 20,
                total: 0,
                timeArr: [],
                startTime: "",
                endTime: "",
                organIdList: [],
                userPopulation: "",
                filledName: "",
                fillStatus: "",
            },
            formTitle: "",
            detailPageTitle: "详情",
            loadingTable: false,
            tableData: [{}],
            detailsObj: [],
            detailsDialog: {
                title: "",
                dialogVisible: false,
                width: "auto",
            },
            tableConfig: {
                thead: [
                    {
                        label: "姓名",
                        // labelWidth: '180px',
                        prop: "filledName",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.filledName ? row.filledName : "-";
                        },
                    },
                    {
                        label: "组织架构",
                        // labelWidth: '180px',
                        prop: "organName",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.organName ? row.organName : "-";
                        },
                    },

                    {
                        label: "表单提交时间",
                        // labelWidth: '180px',
                        prop: "submitTime",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            return row.submitTime ? row.submitTime : "-";
                        },
                    },
                    {
                        label: "来源",
                        // labelWidth: '180px',
                        prop: "source",
                        align: "center",
                        type: "function",
                        callBack: (row) => {
                            let source = {
                                1: "家长端",
                                2: "教师端",
                                3: "二维码",
                                4: "-",
                            };
                            if (!row.source) {
                                return source["4"];
                            }
                            return source[row.source];
                        },
                    },
                    {
                        label: "状态",
                        minWidth: "210px",
                        type: "slot",
                        slotName: "fillStatus",
                        prop: "fillStatus",
                        tooltip: true,
                        align: "center",
                    },
                    {
                        label: "操作",
                        type: "slot",
                        labelWidth: "280px",
                        slotName: "operation",
                        className: "text-spacing-reduction",
                        renderHeader: null,
                    },
                ],
                height: "",
                check: false,
            },
        };
    },
    created() {
        this.findObj.schoolId = this.$store.state.schoolId;
        this.findObj.formId = this.id;
        this.findObj.userPopulation = this.userPopulation;
        console.debug(this.userPopulation, "范围");
        // 教职工
        if (this.userPopulation == 1) {
            this.getBMData();
        }

        // 学生家长
        if (this.userPopulation == 2) {
            this.getClassList();
        }

        // 不限
        if (this.userPopulation == 3) {
            this.isShowFilter = false;
        }
    },
    mounted() {
        this.tableConfig.height =
            document.body.clientHeight -
            document.getElementById("table").offsetTop -
            146;
        window.onresize = debounce(() => {
            if (!document.getElementById("table")) return;
            const height =
                document.body.clientHeight -
                document.getElementById("table").offsetTop -
                146;
            this.tableConfig.height = height.toString();
        }, 200);
        this.getList();
    },
    methods: {
        /**
         * @Description: 拿取部门数据
         * @Author: 周浩
         * @Date: 2024-10-16 14:07:14
         */
        getBMData() {
            this._fet("/school/schoolOrgan/listByConditionNoAuth", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code == 200) {
                    let allList = res.data.data.list.filter(
                        (it) => it.organType == 1,
                    );

                    let options = listToTree(allList, {
                        parentKey: "parentOrg",
                    });
                    this.clearDataNull(options, "children");
                    this.zzOption = options;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        /**
         * @Description: 获取任教班级
         * @Author: 王梦瑶
         * @Date: 2024-08-16 10:42:18
         */
        getClassList() {
            let obj = {
                schoolId: this.$store.state.schoolId,
            };
            this._fet("/school/schoolOrgan/listByCondition", obj).then(
                (res) => {
                    if (res.data.code === "200") {
                        let arr = res.data.data.list.filter((item) => {
                            return item.organType != 1;
                        });
                        const treeData = listToTree(arr, {
                            parentKey: "parentOrg",
                        });

                        this.clearDataNull(treeData, "children");
                        this.zzOption = treeData;
                    } else {
                        this.$message.error("数据请求失败，请重试");
                    }
                },
            );
        },
        clearDataNull(data, key) {
            data.forEach((item) => {
                if (
                    item[key] == null ||
                    item[key] == undefined ||
                    item[key].length == 0
                ) {
                    delete item[key];
                } else {
                    this.clearDataNull(item[key], key);
                }
            });
        },
        searchBtn() {
            this.findObj.startTime = "";
            this.findObj.endTime = "";
            if (this.findObj.timeArr) {
                if (this.findObj.timeArr.length > 0) {
                    this.findObj.startTime = this.findObj.timeArr[0];
                    this.findObj.endTime = this.findObj.timeArr[1];
                }
            }
            this.getList();
        },
        /**
         * @Description: 获取列表数据
         * @Author: 王梦瑶
         * @Date: 2024-08-15 17:37:00
         */
        getList() {
            this.loadingTable = true;
            const InterconnectionInfoCollectionStatosticsModel =
                new InterconnectionInfoCollectionStatostics();
            InterconnectionInfoCollectionStatosticsModel.getSchoolCollectRecordList(
                this.findObj,
            ).then((res) => {
                if (res.data.code === "200") {
                    this.tableData = res.data.data.list;
                    this.findObj.total = res.data.data.total;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }

                this.loadingTable = false;
            });
        },
        /**
         * @Description: 导出全部
         * @Author: 王梦瑶
         * @Date: 2024-08-22 11:43:59
         */
        exportList() {
            this.exportLoading = true;
            downloadFile(
                {
                    url: "/school/schoolCollectRecord/export",
                    method: "post",
                    form: {
                        schoolId: this.$store.state.schoolId,
                        formId: this.id,
                    },
                },
                () => {
                    this.exportLoading = false;
                },
                () => {
                    this.exportLoading = false;
                },
            );
        },
        /**
         * @Description: 修改有效无效答卷
         * @Author: 周浩
         * @Date: 2024-08-15 17:37:00
         */
        updateStatus(id, fillStatus) {
            const InterconnectionInfoCollectionStatosticsModel =
                new InterconnectionInfoCollectionStatostics();
            InterconnectionInfoCollectionStatosticsModel.updateStatus({
                id,
                fillStatus,
            }).then((res) => {
                this.$message({
                    type: "success",
                    message: "修改成功!",
                });
                this.getList();
            });
        },
        /**
         * @Description: 修改页码
         * @Author: 王梦瑶
         * @Date: 2024-08-15 17:46:23
         */
        changeTableDataGet() {
            this.getList();
        },
        /**
         * @Description: 打开查看答案弹窗
         * @Author: 王梦瑶
         * @Date: 2024-08-08 15:58:51
         */
        showAnswer(data) {
            this.formTitle = data.filledName;
            this.getAnwer(data.id);
        },
        getAnwer(id) {
            const InterconnectionInfoCollectionStatosticsModel =
                new InterconnectionInfoCollectionStatostics();
            InterconnectionInfoCollectionStatosticsModel.getAnwer(id).then(
                (res) => {
                    if (res.data.code == 200) {
                        if (
                            !res.data.data.answerInfo ||
                            res.data.data.answerInfo == "{}"
                        ) {
                            this.detailsObj = [];
                        } else {
                            let content = JSON.parse(
                                res.data.data.answerInfo,
                            ).content;
                            let info = JSON.parse(JSON.parse(content));
                            this.detailsObj = info;
                            this.detailsObj.forEach((item) => {
                                if (
                                    item.compType == "multipleSelect" &&
                                    item.value
                                ) {
                                    item.defaultValue = item.value.map((i) => {
                                        return i.label;
                                    });
                                }
                            });
                        }

                        this.detailsDialog.dialogVisible = true;
                    } else {
                        this.$message({
                            type: "error",
                            message: res.data.msg,
                        });
                    }
                },
            );
        },
        /**
         * @Description: 关闭表单详情
         * @Author: 王梦瑶
         * @Date: 2024-08-08 14:57:04
         */
        handleDetailsClose() {
            this.detailsDialog.dialogVisible = false;
        },
        /**
         * @Description: 标记为有效/无效答卷
         * @Author: 王梦瑶
         * @Date: 2024-08-08 15:59:07
         */
        markPaper(data, type) {
            let id = data.id;
            let fillStatus = "3";
            let tit = "不";
            if (type === "有效") {
                tit = "";
                fillStatus = "2";
            }

            let msg = `确定将此问卷标记为“${type}答卷”吗？标记后，该答卷数据${tit}会进入到统计结果中。`;
            this.$confirm(msg, "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.updateStatus(id, fillStatus);
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消修改",
                    });
                });
        },
        resetBtn() {
            this.findObj = this.$options.data().findObj;
            this.findObj.schoolId = this.$store.state.schoolId;
            this.findObj.formId = this.id;
            this.findObj.userPopulation = this.userPopulation;
            this.getList();
        },
    },
};
</script>

<style lang="scss" scoped>
.details-wrapper {
    height: calc(100vh - 175px);
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .filter-wrapper {
        flex-shrink: 0;
        display: flex;
        text-align: center;
        align-items: center;
        background-color: #fff;
        overflow: hidden;
        padding: 10px 0px;

        .formItem {
            margin: 0 5px;
            width: 140px;
        }
        .formItem2 {
            margin: 5px;
            width: 280px;
        }

        .fiBtn {
            margin: 0 5px;
        }
        .btnLine {
            width: 1px;
            height: 25px;
            border-right: 1px dashed #d4d6d9;
            margin: 0 10px;
        }
    }

    .details-content {
        flex: 1;
        overflow: hidden;
    }
}
.invalid-btn {
    color: #e63a2e !important;
}

.close-box {
    text-align: center;
    font-size: 40px;
}

.icon-color {
    color: #fff;
    cursor: pointer;
}
::v-deep {
    .el-input__inner {
        background: #f5f5f5;
        border: transparent 1px solid;
    }
    .el-range-editor .el-range-input {
        background: #f5f5f5;
    }
    .el-cascader__search-input {
        background: #f5f5f5;
    }
    .el-select .el-input .el-select__caret {
        color: #999999;
    }
    .el-input-group__append {
        color: #999999;
        background: #f5f5f5;
        border: none;
    }
    .el-select-dropdown__list {
        border: 1px solid #d4d6d9;
    }
    .el-input__inner:hover {
        border: #d4d6d9 1px solid;
    }
    .el-input__inner:focus {
        border: #3c7fff 1px solid;
        background-color: #fff;
    }
    .el-cascader__tags {
        & > span:nth-of-type(1) {
            flex: 1 !important;
            overflow: hidden;
        }
        & > span:nth-of-type(2) {
            flex-shrink: 0 !important;
            overflow: hidden;
        }
    }
}
</style>
<style lang="scss">
.details-dia {
    .el-dialog {
        background: transparent;
        box-shadow: none;
    }

    .el-dialog .el-dialog__body {
        margin-top: 0px;
        padding: 0px;
    }

    .el-dialog__header {
        display: none !important;
    }
}
.wuxiao {
    color: #e63a2e;
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
}
</style>
